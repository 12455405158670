export default function EncryptIcon() {
  return (
    <svg className='w-8 h-8' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='currentColor'
        d='M11.1 15h1.8q.225 0 .388-.187t.112-.413l-.475-2.625q.5-.25.788-.725T14 10q0-.825-.587-1.412T12 8q-.825 0-1.412.588T10 10q0 .575.288 1.05t.787.725L10.6 14.4q-.05.225.113.413T11.1 15m.9 6.9q-.175 0-.325-.025t-.3-.075Q8 20.675 6 17.637T4 11.1V6.375q0-.625.363-1.125t.937-.725l6-2.25q.35-.125.7-.125t.7.125l6 2.25q.575.225.938.725T20 6.375V11.1q0 3.5-2 6.538T12.625 21.8q-.15.05-.3.075T12 21.9m0-2q2.6-.825 4.3-3.3t1.7-5.5V6.375l-6-2.25l-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3m0-7.9'
      />
    </svg>
  )
}
